import styled from 'styled-components';
import { atMinWidth } from '../../../styles/atoms/breakpoints';
import { colors } from '../../../styles/atoms/colors';
import { font } from '../../../styles/atoms/typography';

export const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  overflow: hidden;
  border-radius: 8px;

  .gatsby-image-wrapper {
    position: absolute;
    inset: 0;
    transition: transform 300ms ease-in-out;
    transform-origin: center;

    ${Container}:hover & {
      transform: scale(1.1);
    }
  }
`;

export const Body = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;

  ${atMinWidth.lg`
    gap: 24px;
  `}
`;
export const Text = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  .solutions-card-heading {
    ${font('display', 'sm', '700')}
    color: ${colors.gray[900]};

    ${atMinWidth.lg`
      ${font('display', 'md', '700')} 
    `}
  }

  .solutions-card-subhead {
    p {
      ${font('text', 'sm', '400')}
      color: ${colors.gray[700]};

      ${atMinWidth.lg`
        ${font('text', 'md', '400')} 
      `}
    }
  }
`;
